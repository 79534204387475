const data = [
  {
    responsive_id: '',
    id: 95,
    full_name: 'Edwina Ebsworth',
    campaign: 'School Fees',
    pricing: '10,000 XAF',
    profit: '10, 000 XAF',
    commission: '100 XAF',
    status: 'Withdrawal',
    date_time: 'Fridat, 5 Jan. 2022 - 11:13 PM',
  },
  {
    responsive_id: '',
    id: 1,
    full_name: "Korrie O'Crevy",
    campaign: 'New Business Center',
    pricing: '1,000,000 XAF',
    profit: '50, 000 XAF',
    commission: '5000 XAF',
    status: 'Deposit',
    date_time: 'Wednesday, 5 Jan. 2021 - 11:03 AM',
  },
  {
    responsive_id: '',
    id: 7,
    full_name: "Falloon O'De",
    campaign: 'Saving the Environmental',
    pricing: '500,000 XAF',
    profit: '30, 000 XAF',
    commission: '3000 XAF',
    status: 'Deposit',
    date_time: 'Wednesday, 5 Jan. 2021 - 10:13 AM',
  },
  {
    responsive_id: '',
    id: 3,
    full_name: 'Stella Ganderton',
    campaign: 'Nuclear Power Project',
    pricing: '35,000 XAF',
    profit: '3000 XAF',
    commission: '300 XAF',
    status: 'Withdrawal',
    date_time: 'Wednesday, 5 Jan. 2021 - 11:00 AM',
  },
  {
    responsive_id: '',
    id: 4,
    full_name: 'Dorolice Crossman',
    campaign: 'Saving for Wedding',
    pricing: '17,000 XAF',
    profit: '2000 XAF',
    commission: '200 XAF',
    status: 'Deposit',
    date_time: 'Friday, 5 Jan. 2021 - 09:13 PM',
  },
]

export default data
