<template>
  <v-row>
    <v-col xs="6" class="text-md-end">
      <v-btn small elevation="10" class="text-xs font-weight-semibold" color="primary darken-2"
        :to="{ name: 'savings.my_faults' }">
        <font-awesome-icon icon="fa-solid fa-plus" class="pe-1" />
        My Faults
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title class="">
          <span class="me-3">My Profits (125,000 frs)</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
          </v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="usreList" item-key="full_name" class="table-rounded"
          :items-per-page="10" disable-sort :search="search">
          <!-- name -->
          <template #[`item.full_name`]="{item}">
            <div class="d-flex flex-column" @click="getMoreInfo(item.id)" style="cursor: pointer">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
              <small>{{ item.campaign }}</small>
            </div>
          </template>
          <template #[`item.pricing`]="{item}">
            {{ item.pricing }}
          </template>
          <template #[`item.profit`]="{item}">
            {{ item.profit }}
          </template>
          <template #[`item.commission`]="{item}">
            {{ item.commission }}
          </template>
          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip
              small
              :color="statusColor[item.status]"
              class="font-weight-medium"
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.date_time`]="{item}">
            {{ item.date_time }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import data from './datatable-data'

export default {
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Withdrawal: 'primary',
      Deposit: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Name', value: 'full_name' },
        { text: 'Amount', value: 'pricing' },
        { text: 'Profit', value: 'profit' },
        { text: 'Commission', value: 'commission' },
        { text: 'Transaction', value: 'status' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      usreList: data,
      statusColor,
      search: '',

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  methods:{
    getMoreInfo(info_id){
      this.$store.commit('setDialogTitle', "Getting More Information for "+info_id)
      this.$store.commit('setDialogMessage', "Your information has been collected. <b>After</b> choosing this, the next field you will have to choose the number of days/weeks/months. <ul><li>This is one</li><li>This is two</li><li>This is three</li><li>This is four</li></ul>")
      this.$store.commit('activateDialog', true)
    }
  },
}
</script>
